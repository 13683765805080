import Highlighter, { HighlighterItem } from '@/components/v2/Highlighter';
import React from 'react';

interface Props {
  title: string;
  description?: string | React.ReactNode;
  url: string;
  image?: string;
  blurColor?: string;
}

export default function ShowcaseCard({
  title,
  description,
  image,
  url,
  blurColor = '#8559F4'
}: Props) {
  return (
      <HighlighterItem>
        <div className="relative h-full bg-gray-950 rounded-[inherit] z-20 overflow-hidden ">
          <div className="w-full h-full flex flex-col md:flex-row md:items-center md:justify-between">
            {/* Blurred shape */}
            <div
              className="  absolute flex items-center justify-center bottom-0 translate-y-3/4 left-1/2 -translate-x-1/2 pointer-events-none -z-10 h-full aspect-square"
              aria-hidden="true"
            >
              <div
                style={{
                  backgroundColor: blurColor
                }}
                className="absolute inset-0 translate-z-0  rounded-full blur-[50px] opacity-70"
              />
              <div className="absolute w-1/4 h-1/4 translate-z-0 bg-primary/25 rounded-full blur-[40px]" />
            </div>
            <div className="relative flex flex-col items-start   p-4 rounded-lg  transition-all  ">
              {/* <div className="absolute -top-px left-20 right-11 h-px bg-gradient-to-r from-primary/0 via-primary/70 to-primary/0" /> */}
              {/* <div className="absolute -bottom-px left-11 right-20 h-px bg-gradient-to-r from-blue-400/0 via-blue-400 to-blue-400/0" /> */}
              <div className="relative z-10 flex h-12 w-12 items-center justify-center rounded-full   ring-gray-900/5 border border-gray-700/50 bg-gray-950 ring-0">
                <img
                  alt=""
                  src={image}
                  width="32"
                  height="32"
                  decoding="async"
                  data-nimg="1"
                  className="h-8 w-8 object-contain"
                  loading="lazy"
                />
              </div>
              <h2 className="mt-6 text-base font-semibold text-gray-100 ">
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="outline-none decoration-transparent ring-0 border-0"
                >
                  <span className="absolute -inset-x-4 -inset-y-6 z-20 sm:-inset-x-6 sm:rounded-2xl"></span>
                  <span className="relative z-10">{title}</span>
                </a>
              </h2>
              <p className="relative z-10 mt-2 text-sm text-gray-400 dark:text-gray-400">
                {description}
              </p>
              <p className="relative z-10 mt-6 flex items-center text-sm font-medium text-gray-200 transition  dark:text-gray-200">
                <svg
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  className="h-6 w-6 flex-none"
                >
                  <path
                    d="M15.712 11.823a.75.75 0 1 0 1.06 1.06l-1.06-1.06Zm-4.95 1.768a.75.75 0 0 0 1.06-1.06l-1.06 1.06Zm-2.475-1.414a.75.75 0 1 0-1.06-1.06l1.06 1.06Zm4.95-1.768a.75.75 0 1 0-1.06 1.06l1.06-1.06Zm3.359.53-.884.884 1.06 1.06.885-.883-1.061-1.06Zm-4.95-2.12 1.414-1.415L12 6.344l-1.415 1.413 1.061 1.061Zm0 3.535a2.5 2.5 0 0 1 0-3.536l-1.06-1.06a4 4 0 0 0 0 5.656l1.06-1.06Zm4.95-4.95a2.5 2.5 0 0 1 0 3.535L17.656 12a4 4 0 0 0 0-5.657l-1.06 1.06Zm1.06-1.06a4 4 0 0 0-5.656 0l1.06 1.06a2.5 2.5 0 0 1 3.536 0l1.06-1.06Zm-7.07 7.07.176.177 1.06-1.06-.176-.177-1.06 1.06Zm-3.183-.353.884-.884-1.06-1.06-.884.883 1.06 1.06Zm4.95 2.121-1.414 1.414 1.06 1.06 1.415-1.413-1.06-1.061Zm0-3.536a2.5 2.5 0 0 1 0 3.536l1.06 1.06a4 4 0 0 0 0-5.656l-1.06 1.06Zm-4.95 4.95a2.5 2.5 0 0 1 0-3.535L6.344 12a4 4 0 0 0 0 5.656l1.06-1.06Zm-1.06 1.06a4 4 0 0 0 5.657 0l-1.061-1.06a2.5 2.5 0 0 1-3.535 0l-1.061 1.06Zm7.07-7.07-.176-.177-1.06 1.06.176.178 1.06-1.061Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span className="ml-[2px] text-sm">{url}</span>
              </p>
            </div>
          </div>
        </div>
      </HighlighterItem>
  );
}
