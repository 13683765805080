'use client';

import { useState, useRef, useEffect } from 'react';
import Image, { StaticImageData } from 'next/image';
import { Transition } from '@headlessui/react';

import TestimonialImg01 from '@/public/saif.jpeg';
import TestimonialImg02 from '@/public/karl.png';
import TestimonialImg03 from '@/public/guilhermo.jpeg';
import Particles from '../v2/Particles';

export default function CustomerTestimonials() {
  const [active, setActive] = useState<number>(0);
  const [autorotate, setAutorotate] = useState<boolean>(true);
  const [autorotateTiming] = useState<number>(7000);

  interface Item {
    img: StaticImageData;
    quote: string;
    name: string;
    role: string;
  }

  const items: Item[] = [
    {
      img: TestimonialImg01,
      quote:
        "Mendables integration on Nylas is a goldmine of data. Now, the product team has a direct source of user feedback, questions, and problems. It's amazing!",
      name: 'Saif Khan',
      role: 'Product @ Nylas'
    },
    {
      img: TestimonialImg02,
      quote:
        'Mendable has allowed us to provide a more enjoyable and better user experience for our documentation readers. This experience benefits our customers and our employees',
      name: 'Karl Cardenas',
      role: 'Director @ SpectroCloud'
    },
    {
      img: TestimonialImg03,
      quote: 'Mendable is exciting in the conversational UI x AI space',
      name: 'Guillermo Rauch',
      role: 'CEO @ Vercel'
    }
  ];

  const testimonials = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!autorotate) return;
    const interval = setInterval(() => {
      setActive(active + 1 === items.length ? 0 : (active) => active + 1);
    }, autorotateTiming);
    return () => clearInterval(interval);
  }, [active, autorotate]);

  const heightFix = () => {
    if (testimonials.current && testimonials.current.parentElement)
      testimonials.current.parentElement.style.height = `${testimonials.current.clientHeight}px`;
  };

  useEffect(() => {
    heightFix();
  }, []);

  return (
    <section>
      <div className="max-w-4xl h-[300px] mt-28 mb-14 mx-auto px-4 sm:px-6">
        <div className="relative pb-12 md:pb-20">
          {/* Particles animation */}
          <div className="absolute left-1/2 -translate-x-1/2 top-0 -z-10 w-80 h-80 -mt-6">
            <Particles
              className="absolute inset-0 -z-10"
              quantity={10}
              staticity={40}
            />
          </div>

          {/* Carousel */}
          <div className="text-center">
            {/* Testimonial image */}
            <div className="relative h-32 [mask-image:_linear-gradient(0deg,transparent,theme(colors.white)_40%,theme(colors.white))]">
              <div className="absolute top-0 left-1/2 -translate-x-1/2 w-[480px] h-[480px] -z-10 pointer-events-none before:rounded-full rounded-full before:absolute before:inset-0 before:bg-gradient-to-b before:from-primary/40 before:to-transparent before:to-20% after:rounded-full after:absolute after:inset-0 after:bg-black after:m-px before:-z-20 after:-z-20">
                {items.map((item, index) => (
                  <Transition
                    key={index}
                    show={active === index}
                    className="absolute top-8 inset-0 h-full -z-10"
                    enter="transition ease-[cubic-bezier(0.68,-0.3,0.32,1)] duration-700 order-first"
                    enterFrom="opacity-0 -rotate-[60deg]"
                    enterTo="opacity-100 rotate-0"
                    leave="transition ease-[cubic-bezier(0.68,-0.3,0.32,1)] duration-700"
                    leaveFrom="opacity-100 rotate-0"
                    leaveTo="opacity-0 rotate-[60deg]"
                    beforeEnter={() => heightFix()}
                  >
                    <Image
                      className="relative   rounded-full"
                      src={item.img}
                      width={56}
                      height={56}
                      alt={item.name}
                    />
                  </Transition>
                ))}
              </div>
            </div>
            {/* Text */}
            <div className="mb-10 h-[100px] transition-all duration-150 delay-300 ease-in-out">
              <div className="relative flex flex-col" ref={testimonials}>
                {items.map((item, index) => (
                  <Transition
                    key={index}
                    show={active === index}
                    enter="transition ease-in-out duration-500 delay-200 order-first"
                    enterFrom="opacity-0 -translate-x-4"
                    enterTo="opacity-100 translate-x-0"
                    leave="transition ease-out duration-300 delay-300 absolute"
                    leaveFrom="opacity-100 translate-x-0"
                    leaveTo="opacity-0 translate-x-4"
                    beforeEnter={() => heightFix()}
                  >
                    <div className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-gray-200/60 via-gray-200 to-gray-200/60">
                      {item.quote}
                    </div>
                  </Transition>
                ))}
              </div>
            </div>
            {/* Buttons */}
            <div className="flex flex-wrap justify-center -m-1.5">
              {items.map((item, index) => (
                <button
                  className={`btn-sm m-1.5 text-xs py-1.5 text-gray-300 transition duration-150 ease-in-out [background:linear-gradient(theme(colors.gray.900),_theme(colors.gray.900))_padding-box,_conic-gradient(theme(colors.gray.400),_theme(colors.gray.700)_25%,_theme(colors.gray.700)_75%,_theme(colors.gray.400)_100%)_border-box] relative before:absolute before:inset-0 before:bg-gray-800/30 before:rounded-full before:pointer-events-none ${
                    active === index
                      ? 'opacity-100'
                      : 'opacity-50 hover:opacity-60'
                  }`}
                  key={index}
                  onClick={() => {
                    setActive(index);
                    setAutorotate(false);
                  }}
                >
                  <span className="relative">
                    <span className="text-gray-50">{item.name}</span>{' '}
                    <span className="text-gray-600">-</span>{' '}
                    <span>{item.role}</span>
                  </span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
