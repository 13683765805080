import React from 'react';
import { getStartedLink } from '../../../utils/constants';
import { Button } from '@/components/app/ui/button';
import signInWithGoogle from '@/components/auth/signInWithGoogle';
import Link from 'next/link';

export default function CTASection() {
  return (
    <>
      <section className="w-full py-32 ">
        <div className="relative px-4 flex flex-col items-center justify-center w-full xl:px-0">
        <div className="max-w-3xl mx-auto text-center ">
            <div>
              <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-mendable-500 to-mendable-200 pb-3">
                Empower your users with AI powered search
              </div>
            </div>
            <h2 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-gray-200/60 via-gray-200 to-gray-200/60 pb-4">
              Build an AI technical assistant in minutes
            </h2>
            {/* <p className="text-lg text-gray-400 max-w-xl self-center mx-auto">
              From small startups to Fortune 500 companies, we're proud to be
              the cutting edge platform for any 'chat with data' applications
            </p> */}
          </div>
          
          <div className="flex flex-col items-center lg:flex-row">
            <div className="flex justify-center items-center w-full h-full mt-8 ">
            <Link href="/signup">
              <Button
                // onClick={() => signInWithGoogle()}
                className="w-full px-8 py-4 bg-transparent text-lg text-center text-gray-50 border border-gray-50 rounded-full md:w-auto hover:bg-gray-50 hover:text-black transition-all"
                data-primary="primary"
                data-rounded="rounded-full"
              >
                Try it out
              </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
