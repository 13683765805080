'use client';

import { useEffect, useState } from 'react';
import Image from 'next/image';

// Import Swiper
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper.min.css';
import Highlighter, { HighlighterItem } from './Highlighter';
import Particles from './Particles';
import ShowcaseCard from '../ui/v1/Cards/ShowcaseCard';
Swiper.use([Navigation, Pagination, Autoplay]);

export default function CompaniesCarousel() {
  const [swiperInitialized, setSwiperInitialized] = useState<boolean>(false);

  useEffect(() => {
    const carousel = new Swiper('.testimonials-carousel', {
      breakpoints: {
        320: {
          slidesPerView: 1
        },
        640: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 3
        }
      },
      // autoplay: {
      //   delay: 5000,
      //   pauseOnMouseEnter: false
      // },
      freeMode: false,
      grabCursor: true,
      loop: false,
      centeredSlides: false,
      initialSlide: 0,
      spaceBetween: 24,
      slidesPerView: 'auto',
      speed: 500,
      navigation: {
        nextEl: '.carousel-next',
        prevEl: '.carousel-prev'
      }
    });
    setSwiperInitialized(true);
  }, []);

  return (
    <section className='z-10'>
      <div className="max-w-6xl z-10 mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <div>
              <div className="inline-flex z-10 font-medium bg-clip-text text-transparent bg-gradient-to-r from-mendable-500 to-mendable-200 pb-3">
                See how companies implement Mendable
              </div>
            </div>
            <h2 className="h2 bg-clip-text z-10 text-transparent bg-gradient-to-r from-gray-200/60 via-gray-200 to-gray-200/60 pb-4">
              Trusted by top companies
            </h2>
            <p className="text-lg z-10 text-gray-400 max-w-xl self-center mx-auto">
              From small startups to Fortune 500 companies, we're proud to be
              a cutting edge platform for any 'chat with data' applications
            </p>
          </div>

          {/* Carousel built with Swiper.js [https://swiperjs.com/] */}
          {/* * Custom styles in src/css/additional-styles/theme.scss */}
          <div className="relative before:absolute before:inset-0 before:-translate-x-full before:z-20 before:bg-gradient-to-l before:from-transparent before:to-black before:to-20% after:absolute after:inset-0 after:translate-x-full after:z-20 after:bg-gradient-to-r after:from-transparent  ">
            
            <div className="testimonials-carousel swiper-container group">
              <Highlighter
                className="swiper-wrapper w-fit"
                refresh={swiperInitialized}
              >
                {/* Carousel items */}
                <div className="swiper-slide h-auto group/slide">
                  <ShowcaseCard
                    title="Snap"
                    url="https://docs.snap.com/"
                    description="Mendable powers an AI chat assistant in Snap's AR documentation"
                    image="/snap-white.png"
                  />
                </div>
                <div className="swiper-slide h-auto group/slide">
                  <ShowcaseCard
                    title="MongoDB"
                    url="https://mongodb.com"
                    description="MongoDB is the world's most popular NoSQL database company."
                    image="/mongo-white.png"
                  />
                </div>
                <div className="swiper-slide h-auto group/slide">
                  <ShowcaseCard
                    title="Worldline"
                    url="https://worldline.com"
                    description="Publicly traded company, Worldline, is Europe's leading payment service provider"
                    image="/worldline_logo_white.png"
                  />
                </div>
                <div className="swiper-slide h-auto group/slide">
                  <ShowcaseCard
                    title="Langchain"
                    url="https://python.langchain.com"
                    description="Langchain is most popular framework for developing modern LLM applications"
                    image="/langchain.png"
                  />
                </div>
                
                <div className="swiper-slide h-auto group/slide">
                  <ShowcaseCard
                    title="Nylas"
                    url="https://nylas.com"
                    description="Nylas gives devs universal access to email, calendar providers through a single integration"
                    image="/nylas.png"
                  />
                </div>
                <div className="swiper-slide h-auto group/slide">
                  <ShowcaseCard
                    title="0x"
                    url="https://0x.org/docs"
                    description="0x offers the core building blocks to create the most powerful Web3 apps"
                    image="/0xlogo.png"
                  />
                </div>
                <div className="swiper-slide h-auto group/slide">
                  <ShowcaseCard
                    title="Merge"
                    url="https://merge.dev"
                    description="Merge is a single API to add hundreds of integrations to your app."
                    image="/merge.png"
                  />
                </div>
                
                <div className="swiper-slide h-auto group/slide">
                  <ShowcaseCard
                    title="Spectrocloud"
                    url="https://docs.spectrocloud.com"
                    description="K8s management uniquely built for scale. Manage the lifecycle of any type of cluster."
                    image="/spectrocloud-white.png"
                  />
                </div>
                <div className="swiper-slide h-auto group/slide">
                  <ShowcaseCard
                    title="Llama Index"
                    url="http://gpt-index.readthedocs.io"
                    description="A central interface to connect your LLM’s with external data."
                    image="/LlamaIndex.png"
                  />
                </div>

                
                <div className="swiper-slide h-auto group/slide">
                  <ShowcaseCard
                    title="Zenlytics"
                    url="https://docs.zenlytic.com"
                    description="Self-serve analytics tool that helps you answer the deeper questions you have about your data"
                    image="/zenlytics.png"
                  />
                </div>

                <div className="swiper-slide h-auto group/slide">
                  <ShowcaseCard
                    title="Code GPT"
                    url="https://www.codegpt.co"
                    description="With over 450,000 installs, CodeGPT brings AI inside your code editor."
                    image="/codegpt.png"
                  />
                </div>
              </Highlighter>
            </div>
          </div>

          {/* Arrows */}
          <div className="flex mt-8 justify-end">
            <button className="carousel-prev relative z-20 w-12 h-12 flex items-center justify-center group">
              <span className="sr-only">Previous</span>
              <svg
                className="w-4 h-4 fill-gray-500 group-hover:fill-mendable-500 transition duration-150 ease-in-out"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.7 14.7l1.4-1.4L3.8 9H16V7H3.8l4.3-4.3-1.4-1.4L0 8z" />
              </svg>
            </button>
            <button className="carousel-next relative z-20 w-12 h-12 flex items-center justify-center group">
              <span className="sr-only">Next</span>
              <svg
                className="w-4 h-4 fill-gray-500 group-hover:fill-mendable-500 transition duration-150 ease-in-out"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export function CompaniesGrid() {
  return (
    <div className="py-24 sm:py-28 z-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 z-10">
      <div className="max-w-3xl mx-auto text-center pb-12 md:pb-12 z-10">
            <div className=''>
              <div className="inline-flex z-10 font-medium bg-clip-text text-transparent bg-gradient-to-r from-mendable-500 to-mendable-200 pb-3">
              From small startups to Fortune 500
              </div>
            </div>
            <h2 className="h2 bg-clip-text z-10 text-transparent bg-gradient-to-r from-gray-200/60 via-gray-200 to-gray-200/60 pb-3">
              Trusted by top companies
            </h2>
            {/* <p className="text-lg z-10 text-gray-400 max-w-xl self-center mx-auto">
              From small startups to Fortune 500 companies, we're proud to be
              a cutting edge platform for any 'chat with data' applications
            </p> */}
          </div>
        <div className="-mx-6 scale-90 grid grid-cols-2 gap-1 overflow-hidden sm:mx-0 sm:rounded-2xl border md:grid-cols-3 lg:grid-cols-5">
          <div className="md:bg-gray-950/50 bg-gray-950 p-8 sm:p-10 flex flex-col items-center justify-center ">
            <img
              className="max-h-12 w-full object-contain"
              src="/customers/snapchat2.svg"
              alt="Snapchat"
              width={158}
              height={48}
            />
          </div>
          <div className="md:bg-gray-950/50 bg-gray-950 p-8 sm:p-10 flex flex-col items-center justify-center ">
            <img
              className="max-h-12 w-full object-contain"
              src="/customers/mongo.svg"
              alt="MongoDB"
              width={158}
              height={48}
            />
          </div>
          
          <div className="md:bg-gray-950/50 bg-gray-950 p-8 sm:p-10 flex flex-col items-center justify-center ">
            <img
              className="max-h-12 w-full object-contain"
              src="/customers/coinbase.svg"
              alt="Langchain"
              width={158}
              height={48}
            />
          </div>
          <div className="md:bg-gray-950/50 bg-gray-950 p-8 sm:p-10 flex flex-col items-center justify-center ">
            <img
              className="max-h-12 w-full object-contain"
              src="/customers/world.svg"
              alt="Worldline"
              width={158}
              height={48}
            />
          </div>
          <div className="md:bg-gray-950/50 bg-gray-950 p-8 sm:p-10 flex flex-col items-center justify-center ">
            <img
              className="max-h-12 w-full object-contain"
              src="/customers/nylass.svg"
              alt="Nylas"
              width={158}
              height={48}
            />
          </div>
          <div className="md:bg-gray-950/50 hidden  bg-gray-950 p-8 sm:p-10 md:flex flex-col items-center justify-center ">
            <img
              className="max-h-12 w-full object-contain"
              src="/customers/spectro.svg"
              alt="Spectrocloud"
              width={158}
              height={48}
            />
          </div>
          <div className="md:bg-gray-950/50 bg-gray-950 p-8 sm:p-10 flex flex-col items-center justify-center ">
            <img
              className="max-h-12 w-full object-contain"
              src="/customers/merge.svg"
              alt="Merge"
              width={158}
              height={48}
            />
          </div>
          <div className="md:bg-gray-950/50 bg-gray-950 p-8 sm:p-10 flex flex-col items-center justify-center ">
            <img
              className="max-h-12 w-full object-contain"
              src="/customers/zeroxx.svg"
              alt="0x"
              width={158}
              height={48}
            />
          </div>
          
          <div className="md:bg-gray-950/50 hidden bg-gray-950 p-8 sm:p-10 lg:flex flex-col items-center justify-center ">
            <img
              className="max-h-12 w-full object-contain"
              src="/customers/tecton.svg"
              alt="Tecton.ai"
              width={158}
              height={48}
            />
          </div>
          <div className="md:bg-gray-950/50  md:flex bg-gray-950 p-8 sm:p-10 flex-col items-center justify-center ">
            <img
              className="max-h-12 w-full object-contain"
              src="/customers/llamaindex.png"
              alt="Llama Index"
              width={158}
              height={48}
            />
          </div>
        </div>
        {/* <p className='mt-8 text-gray-400  w-full flex items-center justify-center mx-auto'>And a lot more that we can't use their logo bc of procurument/contracts bs</p> */}
      </div>
    </div>
  )
}