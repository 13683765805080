import cn from 'classnames';
import createGlobe from 'cobe';
import { motion } from 'framer-motion';
import {
  Code,
  Globe,
  MessageCircle,
  Search,
  Settings2,
  Upload
} from 'lucide-react';
import { X } from 'lucide-react';
import Image from 'next/image';
import {
  JSXElementConstructor,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import colors from 'tailwindcss/colors';

const AnimatedGlobe = ({ className }: { className: string }) => {
  const canvasRef = useRef<any>();

  useEffect(() => {
    let phi = 0;

    const globe = createGlobe(canvasRef.current, {
      devicePixelRatio: 2,
      width: 500 * 2,
      height: 500 * 2,
      phi: 2,
      theta: 0.4,
      dark: 1,
      diffuse: 1.2,
      mapSamples: 16000,
      mapBrightness: 6,
      baseColor: [0.3, 0.3, 0.3],
      markerColor: [0.1, 0.8, 1],
      glowColor: [1, 251 / 255, 241 / 255],
      markers: [],
      onRender: (state) => {
        state.phi = phi;
        phi += 0.003;
      }
    });

    return () => {
      globe.destroy();
    };
  }, []);

  return (
    <canvas className={className} ref={canvasRef} style={{ aspectRatio: 1 }} />
  );
};

export const SourceIcon = ({
  Icon,
  id
}: {
  Icon?: JSXElementConstructor<any>;
  id?: string;
}) => {
  return (
    <div className='relative'>
    <div className="z-20  flex items-center justify-center aspect-square rounded-full border border-gray-800 hover:border-gray-500  p-3 text-white hover:animate-rotate-hover hover:cursor-pointer transition-all">
      {Icon && <Icon  className="h-10 w-10 overflow-hidden m-0 p-0 object-cover aspect-square" />}
      {id && (
        <Image
          alt={id}
          width={36}
          height={36}
          className="h-10 w-10 aspect-square"
          src={`/icons/${id}.svg`}
        />
      )}
    </div>
    </div>
  );
};

export const Lines = ({
  width,
  height,
  radius,
  strokeWidth,
  highlightStrokeWidth,
  strokeDasharray
}: {
  width: number;
  height: number;
  radius: number;
  strokeWidth: number;
  highlightStrokeWidth: number;
  strokeDasharray: number;
}) => {
  const topLinesHeight = Math.round(height / 3);
  const bottomLineHeight = height - topLinesHeight;
  const thirdWidth = Math.round(width / 3);
  const halfWidth = Math.round(width / 2);
  const sixthWidth = Math.round(width / 6);

  const path = `M1 0v${
    topLinesHeight - radius
  }a${radius} ${radius} 0 00${radius} ${radius}h${halfWidth - radius}M${
    width - 1
  } 0v${topLinesHeight - radius}a${radius} ${radius} 0 01-${radius} ${radius}H${
    halfWidth + 1
  }v${bottomLineHeight}m-${sixthWidth} -${height}v${topLinesHeight}m${thirdWidth} -${topLinesHeight}v${topLinesHeight}`;

  return (
    <svg viewBox={`0 0 ${width} ${height}`} fill="none" className="-rotate-90">
      <path
        d={path}
        stroke="#ffffff20"
        strokeDasharray={strokeDasharray}
        strokeWidth={strokeWidth}
      />
      <path
        d={path}
        stroke="url(#pulse)"
        strokeLinecap="round"
        strokeWidth={highlightStrokeWidth}
        strokeDasharray={strokeDasharray}
      />
      <defs>
        <motion.linearGradient
          animate={{
            x1: [0, 0],
            y1: [-2.5 * height, 2 * height],
            x2: [0, 0],
            y2: [-2 * height, 2.5 * height]
          }}
          transition={{
            duration: 2,
            repeat: Infinity
          }}
          id="pulse"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.sky['500']} stopOpacity="0" />
          <stop stopColor={colors.sky['500']} stopOpacity="0.4" />
          <stop offset="1" stopColor={colors.sky['500']} stopOpacity="0" />
        </motion.linearGradient>
      </defs>
    </svg>
  );
};

export const LaunchLine = ({
  height,
  strokeWidth,
  highlightStrokeWidth,
  strokeDasharray
}: {
  height: number;
  strokeWidth: number;
  highlightStrokeWidth: number;
  strokeDasharray: string;
}) => {
  const path = `M1 0v${height}`;

  return (
    <svg viewBox={`0 0 2 ${height / 2}`} fill="none">
      <path
        d={path}
        stroke="#ffffff20"
        strokeDasharray={strokeDasharray}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d={path}
        stroke="url(#pulse1)"
        strokeDasharray={strokeDasharray}
        strokeWidth={highlightStrokeWidth}
        strokeLinecap="round"
      />
      <defs>
        <motion.linearGradient
          animate={{
            x1: [0, 0],
            y1: [1.2 * height, -1 * height],
            x2: [0, 0],
            y2: [1 * height, -1.2 * height]
          }}
          transition={{
            duration: 2,
            repeat: Infinity
          }}
          id="pulse1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.fuchsia['500']} stopOpacity="0" />
          <stop stopColor={colors.fuchsia['500']} stopOpacity="0.7" />
          <stop offset="1" stopColor={colors.fuchsia['500']} stopOpacity="0" />
        </motion.linearGradient>
      </defs>
    </svg>
  );
};

export default function DataIngestion() {
  const sourcesContainerRef = useRef<HTMLDivElement>(null);
  const [sourcesContainerDimensions, setSourcesContainerDimensions] = useState({
    width: 0,
    height: 0
  });

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (!sourcesContainerRef.current) {
        return;
      }

      const sourcesContainerRect =
        sourcesContainerRef.current?.getBoundingClientRect();

      const width = sourcesContainerRect?.width || 0;
      const height = sourcesContainerRect?.height || 0;

      setSourcesContainerDimensions({
        width,
        height
      });
    });

    sourcesContainerRef.current &&
      observer.observe(sourcesContainerRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <section className="relative pt-10">
      <div className="max-w-6xl h-min mx-auto px-4 sm:px-6">
        <div className="flex py-12 md:py-20">
          <div className="relative  h-min  w-full">
            <p className="my-4 text-3xl font-bold  sm:text-5xl z-100 bg-clip-text text-transparent bg-gradient-to-r from-gray-300 via-white to-gray-300 animate-text">
              Connect your data sources
            </p>
            <div className="mt-8 relative z-0 grid w-fit grid-cols-8 items-center justify-center gap-4">
              <SourceIcon id="github-mark" />

              <SourceIcon id="medium" />
              <SourceIcon id="gitbook" />
              <SourceIcon id="docusaurus" />
              <SourceIcon id="readme" />
              <SourceIcon id="notion" />
              <SourceIcon Icon={Upload} />

              <SourceIcon id="confluence" />

              <SourceIcon id="drive" />
              <SourceIcon id="youtube" />
              <SourceIcon id="pdf" />
              <SourceIcon id="wordpress" />

              <SourceIcon id="slack" />
              <SourceIcon id="readme" />

              <SourceIcon Icon={Globe} />
              <SourceIcon id="zendesk" />
              

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
