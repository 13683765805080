import React from 'react';

export default function ToolsHero({className}:{
  className?: string
}) {
  return (
    <section className={`relative ${className}`}>
      {/* Bg gradient */}
      <div
        className="absolute top-0 left-0 right-0 bg-gradient-to-b from-gray-800 to-gray-900 opacity-60 h-[10rem] pointer-events-none -z-10"
        aria-hidden="true"
      />
      {/* Bg illustration */}

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-5xl  mx-auto text-center pb-4 md:pb-8">
            <div>
              <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-mendable-500 to-mendable-200 pb-3">
                More than just a chatbot
              </div>
            </div>
            <h2 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-gray-200/60 via-gray-200 to-gray-200/60 pb-4">
              Tools and Actions
            </h2>
            <p className="text-base sm:text-lg text-gray-400 max-w-xl self-center mx-auto">
              Give your AI access to tools for augmentation and actions for
              automation. Integrate with any API
            </p>
          </div>
          {/* Section content */}
          <div className="max-w-6xl mx-auto relative ">
            <div className="max-w-3xl mx-auto w-full ">
              <div className="flex justify-center items-center gap-4 ">
                <img src="/tools-purple.svg" className="h-[52px]" />
                <img src="/actions-yellow-svg.svg" className="h-14 mb-[2px]" />
              </div>
            </div>
            <img
              src="/tools-pic.png"
              className="mt-10 mx-auto w-[600px] border border-primary/25 rounded-xl box-shadow"
            />

            {/* <div
                className="max-w-3xl flex items-center justify-between gap-4 mt-4"
                data-aos="zoom-out"
              >
                <div className="">
                <CodeDisplaySimple code={defaultCode} />
                </div>

                <div className="">
                  <CodeDisplaySimple code={code2} />
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
