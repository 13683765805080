import { cn } from '@/lib/utils'
import Script from 'next/script'
import React from 'react'

export default function SenjaTweets({
  className,
}:{
  className?:string
}) {
  return (
    <div className={cn("mx-auto max-w-7xl px-6 lg:px-8", className)}>
      <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <div>
              <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-mendable-500 to-mendable-200 pb-3">
                {"Our wall of love"}
              </div>
            </div>
            <h2 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-gray-200/60 via-gray-200 to-gray-200/60 pb-4">
              Don't take our word for it
            </h2>
            {/* <p className="text-lg text-gray-400 max-w-xl self-center mx-auto">
              From small startups to Fortune 500 companies, we're proud to be
              the cutting edge platform for any 'chat with data' applications
            </p> */}
          </div>
        <div
          className="senja-frame-embed"
          data-id="d38fd75d-d434-4721-813c-ec370c94b622"
        ></div>
        <Script
          async
          type="text/javascript"
          src="https://widget.senja.io/embed/frame.js"
        ></Script>
      </div>
  )
}
